define("adtikir3v1/models/list-vendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    vendor: belongsTo('company'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    preferredFlag: attr('boolean')
  });

  _exports.default = _default;
});