define("adtikir3v1/pods/components/adkcard-addashboard-adtargeting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   sort
  // } from '@ember/object/computed';
  var _default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    isEditing: false,
    // languageoptions: computed('ad.adcampaigndetail.languages.[]', function(){
    //   let array=[];
    //   let languages = this.get('ad.adcampaigndetail.languages.id');
    //   languages.forEach(function(item,index){
    //     array.pushObject({
    //             index: index,
    //     });
    //   });
    //   return array;
    // }),
    actions: {
      toggleEdit() {
        this.toggleProperty('isEditing');
      },

      saveTargetInfo() {
        var ctrl = this;
        let ad = this.get('ad');
        ctrl.toggleProperty('isEditing');
        ad.save().then(onEditOK, onEditFailed);

        function onEditOK() {
          let adkToast = ctrl.get('adkToast'); // ctrl.resetForm();

          adkToast.display("Ad Targeting successfully updated", "warning");
        }

        function onEditFailed() {
          let adkToast = ctrl - dashboard - description.get('adkToast');
          adkToast.display("Ad Targeting not saved", "error");
        }
      }

    }
  });

  _exports.default = _default;
});