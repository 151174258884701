define("adtikir3v1/router", ["exports", "adtikir3v1/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('help');
    this.route('profile', function () {
      this.route('company', function () {
        this.route('additional');
        this.route('agency-media');
        this.route('customization');
        this.route('management');
        this.route('prefer-vendor');
      }); //company
    }); //profile

    this.route('newcompany');
    this.route('campaign', function () {
      this.route('details', {
        path: '/:ad_campaign_id'
      }, function () {
        this.route('activities');
        this.route('ads', function () {
          this.route('adnew');
          this.route('timeline');
          this.route('workflow');
          this.route('ad', {
            path: '/:ad_id'
          }, function () {
            this.route('activities');
            this.route('approval');
            this.route('dashboard');
            this.route('financials', function () {
              this.route('account');
              this.route('budget');
              this.route('invoice');
              this.route('quickbudget');
            });
            this.route('reports');
            this.route('vendors', function () {
              this.route('agency', function () {
                this.route('rfpmanagement');
                this.route('proposal');
                this.route('rfp');
              });
              this.route('media', function () {
                this.route('rfpmanagement');
                this.route('rfp');
                this.route('proposal');
              });
              this.route('adclientbrief');
            }); //vendor

            this.route('vendor', function () {});
          }); //ad
        }); //ads

        this.route('approval');
        this.route('cedit');
        this.route('dashboard');
        this.route('financials', function () {
          this.route('invoicing');
          this.route('accounts');
          this.route('budget');
        }); //financials

        this.route('planner');
        this.route('reports', function () {
          this.route('campaignoverall');
          this.route('campaignaddetails');
          this.route('financials');
        }); //reports

        this.route('targeting');
        this.route('timeline');
        this.route('vendor', function () {
          // this.route('clientbrief');
          // this.route('proposal', function () {
          //   this.route('agency');
          // });
          // this.route('rfp', function () {
          //   this.route('agency');
          // });
          this.route('media', function () {
            this.route('clientbrief');
            this.route('rfpmanagement');
            this.route('rfp');
            this.route('proposal');
            this.route('accepted');
          });
          this.route('agency', function () {
            this.route('clientbrief');
            this.route('proposal');
            this.route('rfp');
          });
        });
      }); //details

      this.route('new');
      this.route('loading');
    });
    this.route('agency', function () {
      this.route('request');
      this.route('awards', function () {});
      this.route('projectmgmt', function () {});
      this.route('admanager', function () {
        this.route('ad', {
          path: '/:ad_id'
        }, function () {
          this.route('dashboard');
          this.route('financials', function () {
            this.route('invoice');
            this.route('account');
          });
          this.route('approval');
          this.route('reports');
        });
      }); //admanager
    }); //agency

    this.route('media', function () {
      this.route('request');
      this.route('awards');
      this.route('projectmgmt');
      this.route('admanager', function () {
        this.route('ad', {
          path: '/:ad_id'
        }, function () {
          this.route('dashboard');
          this.route('financials', function () {
            this.route('account');
            this.route('invoice');
          });
        });
      }); //admanager
    }); //media

    this.route('details', function () {
      this.route('vendor', function () {
        this.route('media', function () {});
      });
    });
    this.route('adagency', function () {
      this.route('details', function () {});
    });
  });
  var _default = Router;
  _exports.default = _default;
});